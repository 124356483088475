<template>
    <div class="wrapper">
        <div class="bottom">
            <el-form :model="registrationSecurityCodeForm" ref="registrationSecurityCodeForm" :rules="rules">
                <el-form-item>
                    <slot name="title"></slot>
                </el-form-item>
                <el-form-item prop="account">
                    <el-input class="input" v-model="registrationSecurityCodeForm.account" placeholder="手机号或者邮箱" round/>
                </el-form-item>
                <el-form-item prop="code">
                    <el-row>
                        <el-input class="input2" v-model="registrationSecurityCodeForm.code" placeholder="请输入验证码" round>
                            <template slot="append">
                                <div class="pointer" v-if="securityCodeTimeOut === 0" @click="checkUser">发送验证码</div>
                                <div class="pointer" v-if="securityCodeTimeOut !== 0">{{securityCodeTimeOut}}s重试</div>
                            </template>
                        </el-input>
                    </el-row>
                </el-form-item>
                <el-form-item>
                    <el-button @click="checkRegisterCode"  @keydown.enter.native="checkRegisterCode"  type="primary" class="login">
                        <slot name="button"></slot>
                    </el-button>
                </el-form-item>
                <el-form-item style="text-align: center;">
                    <slot name="buttom"></slot>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
    import {commitMsg} from "../../request/api/smsc.js";
    import {checkRegisterCode, checkUser} from "../../request/api/merchant.js";
    import rules from "../../views/login/js/rules";
    import Language from "./Language.vue";
    import CommonLogin from "../../utils/CommonLogin.js";
    import ThirdPartyRegister from "./ThirdPartyRegister.vue";
    let Base64 = require('js-base64').Base64;
    export default {
        name: "RegisterForm",
        components: {ThirdPartyRegister, Language},
        data(){
            return {
                rules: rules.rules,
                show: '',
                securityCodeTimeOut: 0,
                registrationSecurityCodeForm: {
                    account:'',
                    code: '',
                },
                emailAndMobile: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$',
            }
        },
        mounted() {
            let _this = this;
            document.onkeydown = function(e) {
                if (e.key === "Enter" || e.code === "Enter") {
                   if (_this.registrationSecurityCodeForm.account && _this.registrationSecurityCodeForm.code){
                       _this.checkRegisterCode()
                   }
                }
            }
        },
        beforeDestroy() {
            document.onkeydown = undefined;
        },
        methods: {
            checkUser() {
                let account = this.registrationSecurityCodeForm.account;
                let _this = this;
                if (account && account.match(this.emailAndMobile)) {
                    let data = {
                        'account': account,
                        'exist': true,
                    }
                    checkUser(data).then((result) => {
                        if (result.code === 200){
                            _this.commitMsg()
                        }else {
                            _this.$notify({
                                title: _this.$t('login.hint'),
                                message: result.message,
                                type: 'warning'
                            });
                        }
                    })
                }
            },
            commitMsg() {
                let _this = this;
                let data = {
                    'toFrom': this.registrationSecurityCodeForm.account,
                    'typeNum': 2,
                };
                commitMsg(data).then(result => {
                    if (result.code === 200){
                        _this.securityCodeTimeOut = 60;
                        let interval = setInterval(() => {
                            if (_this.securityCodeTimeOut > 0){
                                _this.securityCodeTimeOut -= 1;
                            }else {
                                clearInterval(interval)
                            }
                        },1100);
                        _this.$notify({
                            title: _this.$t('login.succeed'),
                            message: _this.$t('login.captchaCodeSentSuccessfully'),
                            type: 'success'
                        });
                    }else {
                        _this.$notify({
                            title: _this.$t('login.hint'),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            checkRegisterCode() {
                let _this = this;
                this.$refs["registrationSecurityCodeForm"].validate((val) => {
                    if (val){
                        let data = {
                            "account": this.registrationSecurityCodeForm.account,
                            "code": this.registrationSecurityCodeForm.code
                        };
                        checkRegisterCode(data).then((result) =>{
                            console.log(result);
                            if (result.code === 200){
                                this.$router.push({
                                    path: "/login/completeInformation",
                                    query: {
                                        datum: Base64.encode(JSON.stringify({
                                            account : _this.registrationSecurityCodeForm.account,
                                            code: _this.registrationSecurityCodeForm.code,
                                        }))
                                    }
                                })
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../assets/style/index.less";
    @input-width: 308px;

    .wrapper {
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
    .input {
        width: 308px;
        height: 40px;
    }
    .input2 {
        width: 308px;
        height: 40px;

        &/deep/ .el-input__inner{
            border-right: 0px;
        }
        &/deep/ .el-input-group__append {
            background-color: white;
            color: @color-primary;
        }
    }

    .login {
        width: @input-width;
    }
    .pointer {
        cursor: pointer;
    }
    .text-color {
        .pointer;
        color:  @color-primary;
    }
    .forget-password{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: #666666;
    }
</style>
