<template>
    <div class="wrapper">
        <div class="top">
            <Language/>
        </div>
        <div class="bottom">
            <RegisterForm>
                <template slot="title">
                    <span style="font-size: 18px; font-weight: bold;">{{$t('login.signGreatAccountChaozan')}}</span>
                </template>
                <template slot="button">
                    {{$t('login.freeRegistration')}}
                </template>
                <template slot="buttom">
                    <el-row>
                        <el-col>{{$t('login.signOfApproval')}}<span class="text-color" @click="goAgreement">{{$t('login.userServiceAgreement')}}</span></el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <el-col>{{$t('login.alreadyAccount')}}
                                <span class="text-color" @click="()=>{
                                this.$router.replace('/login')
                            }">
                                {{$t('login.text')}}
                            </span>
                            </el-col>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col>
                            <ThirdPartyRegister/>
                        </el-col>
                    </el-row>
                </template>
            </RegisterForm>
        </div>
    </div>
</template>

<script>
    import {commitMsg} from "../../../request/api/smsc";
    import {checkRegisterCode, checkUser} from "../../../request/api/merchant";
    import rules from "../js/rules";
    import Language from "../../../components/login/Language";
    import CommonLogin from "../../../utils/CommonLogin";
    import ThirdPartyRegister from "../../../components/login/ThirdPartyRegister";
    import RegisterForm from "../../../components/login/RegisterForm";
    let Base64 = require('js-base64').Base64;
    export default {
        name: "Register",
        components: {RegisterForm, ThirdPartyRegister, Language},
        data(){
            return {
                rules: rules.rules,
                show: '',
                securityCodeTimeOut: 0,
                registrationSecurityCodeForm: {
                    account:'',
                    code: '',
                },
                emailAndMobile: '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)])|^(?:0|86|\\+86)?1[3-9]\\d{9}$',
            }
        },
        mounted() {

        },
        methods: {
            checkUser() {
                let account = this.registrationSecurityCodeForm.account;
                let _this = this;
                if (account && account.match(this.emailAndMobile)) {
                    let data = {
                        'account': account,
                        'exist': true,
                    };
                    checkUser(data).then((result) => {
                        if (result.code === 200){
                            _this.commitMsg()
                        }else {
                            _this.$notify({
                                title: _this.$t('login.hint'),
                                message: result.message,
                                type: 'warning'
                            });
                        }
                    })
                }
            },
            commitMsg() {
                let _this = this;
                let data = {
                    'toFrom': this.registrationSecurityCodeForm.account,
                    'typeNum': 2,
                };
                commitMsg(data).then(result => {
                    if (result.code === 200){
                        _this.securityCodeTimeOut = 60;
                        let interval = setInterval(() => {
                            if (_this.securityCodeTimeOut > 0){
                                _this.securityCodeTimeOut -= 1;
                            }else {
                                clearInterval(interval)
                            }
                        },1100);
                        _this.$notify({
                            title: _this.$t('login.succeed'),
                            message: _this.$t('login.captchaCodeSentSuccessfully'),
                            type: 'success'
                        });
                    }else {
                        _this.$notify({
                            title: _this.$t('login.hint'),
                            message: result.message,
                            type: 'warning'
                        });
                    }
                })
            },
            isParentShow(key) {
                this.$emit('others',key);
            },
            checkRegisterCode() {
                let _this = this;
                this.$refs["registrationSecurityCodeForm"].validate((val) => {
                    if (val){
                        let data = {
                            "account": this.registrationSecurityCodeForm.account,
                            "code": this.registrationSecurityCodeForm.code
                        };
                        checkRegisterCode(data).then((result) =>{
                            console.log(result);
                            if (result.code === 200){
                                this.$router.push({
                                    path: "/login/completeInformation",
                                    query: {
                                        datum: Base64.encode(JSON.stringify({
                                            account : _this.registrationSecurityCodeForm.account,
                                            code: _this.registrationSecurityCodeForm.code,
                                        }))
                                    }
                                })
                            }else {
                                _this.$notify({
                                    title: _this.$t('login.hint'),
                                    message: result.message,
                                    type: 'warning'
                                });
                            }
                        })
                    }else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        //    协议
            goAgreement(){
                this.$router.replace("/agreement/serviceAgreement");
            },
        }
    }
</script>

<style scoped lang="less">
    @import "../../../assets/style/index";
    @input-width: 400px;

    .wrapper {
        width: 100%;
        height: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top {
            width: 100%;
            height: 25px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            padding-right: 50px;
            padding-top: 7px
        }

        .bottom {
            width: 100%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }

    .login {
        width: @input-width;
        text-align: center;
        cursor: pointer;
        color: white;
        background-color: @color-primary;
    }
    .pointer {
        cursor: pointer;
    }
    .text-color {
        .pointer;
        color:  @color-primary;
    }
    .forget-password{
        display: flex;
        justify-content: flex-end;
        font-size: 12px;
        color: #666666;
    }
</style>
