<template>
    <div>
        <el-divider>{{$t('login.otherRegistrationMethods')}}</el-divider>
        <el-row style="text-align: center;">
            <el-col>
                <el-image class="third-party-register-icon"
                          :src="require('@/assets/edit-imgs/login/wechat.png')"
                          @click="()=>{
                            this.$router.replace('/login/registerWeChatQRCode')
                          }"
                />
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ThirdPartyRegister"
    }
</script>

<style scoped lang="less">
    .third-party-register-icon{
        cursor: pointer;
        width: 32px;
        height: 32px;
        line-height: 32px;
        border-radius: 32px;
        color: white;

        &:hover {
            transition: 0.3s;
            opacity: 0.8;
        }
    }
</style>
